/* Shared */
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */
.loginBtn--facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354c8c;
}
.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png")
    6px 6px no-repeat;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
}

button.register-button {
  width: 150px;
  min-height: 300px;
  color: #9e1317;
}

.h1small {
  font-size: 0.75em !important;
}

.global-nav-desktop {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.myhunts {
}

.myhunts table tr {
  cursor: pointer;
}

.DateInput {
  width: 100% !important;
}
.DateInput_input {
  width: auto;
}

.SingleDatePickerInput {
  border: none !important;
}

.skuCard .ui.ribbon.label {
  left: calc(1em - 2.2em);
}

.move {
  cursor: move;
}

.dragging.item {
  /*background-color: red;*/
  margin: 0;
  border-radius: 0;
  padding: 0.5em 0.5em;
  display: list-item;
  table-layout: fixed;
  list-style-type: none;
  list-style-position: outside;
  line-height: 1.14285714em;
  font-size: 1.14285714em;
}

.dragging.item > i.icon {
  display: table-cell;
  margin: 0;
  padding-top: 0;
  padding-right: 0.28571429em;
}

.dragging.item .icon + .content {
  display: table-cell;
  padding: 0 0 0 0.5em;
  vertical-align: top;
}

.dragging.item > .content {
  line-height: 1.14285714em;
}

.dragging.item .header {
  display: block;
  margin: 0;
  font-family: Lato, "Helventica Neueu", Arial, Helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.87);
}

.dragging.item [class*="middle aligned"] {
  vertical-align: middle !important;
}

.dragging.item [class*="right floated"] {
  float: right;
}

@media only screen and (max-width: 400px) {
  .manage-challenges > .ui.basic.segment.challenge-list-segment {
    padding: 0;
  }

  .manage-challenges
    > .ui.basic.segment.challenge-list-segment
    > .list
    > .item {
    padding-left: 0;
    padding-right: 0;
  }
}

.hunt-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  background-color: white;
}

.manage-hunt {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -70px;
}

.push {
  height: 70px;
}

.manage-hunt {
  padding: 0px 0px 20px 0px;
}

.manage-hunt-body h2.ui.header {
  margin-top: -0.14285714em;
}

@media only screen and (max-width: 400px) {
  .manage-hunt {
    margin-top: -2rem;
  }
}

@media only screen and (min-width: 401px) {
  .ScavrApp {
    margin-top: 45px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

