.DateInput {
  width: 100% !important;
}
.DateInput_input {
  width: auto;
}

.SingleDatePickerInput {
  border: none !important;
}

.skuCard .ui.ribbon.label {
  left: calc(1em - 2.2em);
}
