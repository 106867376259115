.hunt-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  background-color: white;
}

.manage-hunt {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -70px;
}

.push {
  height: 70px;
}
