.manage-hunt {
  padding: 0px 0px 20px 0px;
}

.manage-hunt-body h2.ui.header {
  margin-top: -0.14285714em;
}

@media only screen and (max-width: 400px) {
  .manage-hunt {
    margin-top: -2rem;
  }
}
