.move {
  cursor: move;
}

.dragging.item {
  /*background-color: red;*/
  margin: 0;
  border-radius: 0;
  padding: 0.5em 0.5em;
  display: list-item;
  table-layout: fixed;
  list-style-type: none;
  list-style-position: outside;
  line-height: 1.14285714em;
  font-size: 1.14285714em;
}

.dragging.item > i.icon {
  display: table-cell;
  margin: 0;
  padding-top: 0;
  padding-right: 0.28571429em;
}

.dragging.item .icon + .content {
  display: table-cell;
  padding: 0 0 0 0.5em;
  vertical-align: top;
}

.dragging.item > .content {
  line-height: 1.14285714em;
}

.dragging.item .header {
  display: block;
  margin: 0;
  font-family: Lato, "Helventica Neueu", Arial, Helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.87);
}

.dragging.item [class*="middle aligned"] {
  vertical-align: middle !important;
}

.dragging.item [class*="right floated"] {
  float: right;
}

@media only screen and (max-width: 400px) {
  .manage-challenges > .ui.basic.segment.challenge-list-segment {
    padding: 0;
  }

  .manage-challenges
    > .ui.basic.segment.challenge-list-segment
    > .list
    > .item {
    padding-left: 0;
    padding-right: 0;
  }
}
